// a request helper which reads the access_token from the redux state and passes it in its HTTP request
import Cookies from "js-cookie";

const request = function (
  url,
  method = "GET",
  body,
  opts = {},
  withToken = true
) {
  let options;

  options = {
    headers: {},
    method: method,
  };
  if (withToken) {
    if (process.env.REACT_APP_IS_QIWA_SSO_ENABLED == "false") {
      if (!window.kc) window.location.reload();
      refreshToken();
      const token = window.kc && window.kc.token;

      if (!!token) {
        options.headers["Authorization"] = `Bearer ${token}`;
      }
    } else {
      let token = Cookies.get("token");
      console.log(
        " request : access token from storage ",
        localStorage.getItem("token")
      );
      console.log(" request : token from cookie ", Cookies.get("token"));
      if (token) {
        options.headers["Authorization"] = `Bearer ${token}`;
      }
    }
  }

  if (!opts.upload) {
    options.headers["Content-Type"] = "application/json";

    if (
      method == "POST" ||
      method == "PUT" ||
      method == "PATCH" ||
      method === "DELETE"
    ) {
      options.body = body ? JSON.stringify(body) : {};
    }
  } else {
    options.body = body;
  }

  const p = fetch(process.env.REACT_APP_API_URI + "/api" + url, options);

  p.then((res) => {
    if (res.status === 401) {
      window.location =
        process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }
    if (res.status === 403) {
      localStorage.removeItem("selected");
      window.location =
        process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/Unauthorized";
    }

    if (!res.ok) {
      throw new Error("Failed request");
    }
  });

  return p;
};

const parseError = (response) => {
  const error = new Error(response.statusText);
  error.status = response.status;
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return response.json().then((json) => {
      error.error = json.error;
      error.reason = json.reason;
      return Promise.reject(error);
    });
  }

  return response.text().then((text) => {
    try {
      const json = JSON.parse(text);
      error.reason = json.title || json.message;
      error.rawResponse = json;
    } catch (err) {
      error.reason = text;
    }
    return Promise.reject(error);
  });
};

const refreshToken = () => {
  window.kc.onTokenExpired = () => {
    window.kc
      .updateToken(30)
      .then(() => {})
      .catch(() => {
        console.log("error for getting refresh token");
      });
  };

  window.kc
    .updateToken(30)
    .then(() => {})
    .catch(() => {
      console.log("error for getting refresh token");
    });
};

export default request;
export { parseError, refreshToken };
