import React from "react";
import PopUp from "../../views/Components/PopUp/PopUp";
import {Text} from "../../../../shared-components/my-scope/atoms/text";
import "./privacyPolicyModal.scss";
const PrivacyPolicyModal = ({ isOpen, onRequestClose, onAccept }) => {
  return (
      <PopUp
        isOpen={isOpen}
        maxWidth={680}
        title="سياسة الخصوصية"
        body="للإستفادة من خدمات المنصة يرجى الموافقة على سياسة الخصوصية، يمكنك الإطلاع عليها من خلال صفحة"
        bodyLink={
          <>
            <a href={`${process.env.REACT_APP_ESTABLISHMENT_BASE_URI}/#/privacyPolicy`}
               target="_blank">
              <Text
                Tag="span"
                fontSize={"font-22"}
                textType="roman"
                mobileFontSize={"font-16-mobile"}
                mobileTextType="bold"
                className="privacy-policy-modal-link">
              {" "}
              سياسة الخصوصية
              </Text>
            </a>
            .
          </>
        }
        actionButtonText="أوافق"
        cancelButtonText="إلغاء"
        onClickAction={onAccept}
        onClickCancel={onRequestClose}
      />
  )
};

export default PrivacyPolicyModal;
