import React, { Fragment, useEffect } from "react";
import Content from "../../containers/Content/Content";

import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { privacyData } from "./data";
import "./privacyPolicy.scss";

function PrivacyPolicy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Content history={props.history}>
      <div className="animated fadeIn privacy-policy-page-margins">
        <TitleWithSubTitle
          subtitle={privacyData.title}
          customStyle="privacy-title"
        />
        <Text
          Tag="div"
          textType="bold"
          fontSize={"font-22"}
          mobileFontSize={"font-18-mobile"}
          className="privacy-policy-description"
        >
          {privacyData.description}
        </Text>
        {privacyData.sections.map((section, index) => {
          return (
            <Fragment key={index}>
              <Text
                Tag="div"
                textType="bold"
                fontSize={"font-26"}
                mobileFontSize={"font-20-mobile"}
                className="privacy-policy-section-title"
              >
                {section.title}
              </Text>

              {section.subsections.map((subsection, i) => {
                return (
                  <div key={i}>
                    <Text
                      Tag="div"
                      textType="bold"
                      fontSize={"font-22"}
                      mobileFontSize={"font-18-mobile"}
                      className="privacy-policy-subsection-title"
                    >
                      {subsection.title}
                    </Text>
                    {typeof subsection.paragraph === "string" ? (
                      <Text
                      Tag="div"
                      textType="roman"
                      fontSize={"font-26"}
                      mobileFontSize={"font-18-mobile"}
                      className="privacy-policy-subsection-description"
                      >
                        {subsection.paragraph}
                      </Text>
                    ) : (
                      subsection.paragraph.map((item, index) => {
                        if (typeof item === "string" && item.includes("<br />"))
                          return (
                            <span key={index}>
                              <br />
                            </span>
                          );
                        else
                          return (
                            <Text
                              key={index}
                              Tag="div"
                             textType="roman"
                             fontSize={"font-26"}
                             mobileFontSize={"font-18-mobile"}
                             className="privacy-policy-subsection-description"
                            >
                              {typeof item === "string" ? (
                                item // Render the string normally
                              ) : (
                                <>
                                  <b>{item.label}</b> {/* Render label with bold styling */}
                                  {item.description} {/* Render description normally */}
                                </>
                              )}
                            </Text>
                          );
                      })
                    )}
                  </div>
                );
              })}
            </Fragment>
          );
        })}
        <Text
        Tag="div"
        textType="bold"
        fontSize={"font-26"}
        mobileFontSize={"font-18-mobile"}
        className="privacy-policy-contact-us"
         >
          {privacyData.contactUs}
        </Text>
      </div>
    </Content>
  );
}

export default PrivacyPolicy;
