export const privacyData = {
  title: "سياسة الخصوصية",
  description:
"موقع التقييم الذاتي هو موقع إلكتروني (\"موقعنا\")  (https://ims.hrsd.gov.sa)يقدم خدمات لأصحاب المنشآت تساهم في رفع امتثالهم لأنظمة ولوائح وزارة الموارد البشرية والتنمية الاجتماعية ،ويحوي موقعنا المعلومات التي يتوجب على المستخدم معرفتها حيال سرية المعلومات وخصوصية بيانات المستخدمين والتي تعتبر معلومات بالغة الأهمية، ويتمثل هدفنا والتزامنا في تقديم خدمة ذات جودة عالية لجميع المستخدمين مع الحفاظ على أعلى درجات الخصوصية. ولذلك جرت صياغة سياسة إشعار الخصوصية هذه لمساعدتك على فهم طبيعة البيانات الشخصية التي نجمعها منك والغرض من جمعها ومعالجة تلك البيانات، وحتى تكون على اطلاع بكيفية استخدام شركة تكامل لخدمات الأعمال (\"الشركة\") لبياناتك الشخصية وحقوقك فيما يتعلق بمعالجة بياناتك الشخصية وفقا للأنظمة والتشريعات ذات الصلة في المملكة العربية السعودية. فيعتبر هذا الاشعار والمعلومات الموضحة أدناه جزءاً من شروط استخدام موقعنا.",
  contactUs:
    "في حال وجود أي ملاحظة أو استفسار أو شكوى تتعلق بسياسة الخصوصية الخاصة بمنصة التقييم الذاتي، يمكنك التواصل مع مكتب إدارة البيانات عبر البريد الإلكتروني التالي: IMS@Takamol.sa",
  sections: [
    {
      subsections: [
        {
          title: "الموافقة على سياسة الخصوصية",
          paragraph:
            'إن استخدامك لموقعنا يعني موافقتك على سياسة الخصوصية، وتعني الموافقة على هذا الإشعار موافقتك على كل من الآتي وذلك على سبيل المثال لا الحصر: (جمع بياناتك الشخصية، ومعالجة بياناتك الشخصية، وتخزين بياناتك الشخصية، والإفصاح عن بياناتك الشخصية ومشاركتها للجهات الحكومية حسب الاحتياجات النظامية والقانونية) وحسب السياسات والضوابط والشروط التي تحكم الموقع.',
        },
        {
          title: "التغييرات التي تطرأ على سياسة الخصوصية",
          paragraph:
            "يجب عليك الاطلاع بشكل مستمر على الإشعار والسياسات المرتبطة بها لمتابعة أي تحديثات أو تغييرات قد ندخلها على سياسة الخصوصية، ان استخدامك للموقع يعني اطلاعك وموافقتك على سياسة الخصوصية، وما يتم عليه من تعديلات مستمرة. كما إنك مسؤول بمفردك عن تمام وصحة وصدق بياناتك التي تدخلها من خلال هذا الموقع.",
        },
        {
          title: "البيانات والمعلومات التي نجمعها عنكم",
          paragraph: [
            "فيما يتعلق بكل زيارة من زياراتكم لموقعنا،  يتم جمع المعلومات التالية:",
            "- البيانات الشخصية، البيانات المدخلة من المستخدم، والتي تكون مطلوبة عند التسجيل في القنوات التي توفرها الشركة لخدمة المستخدمين (مثل الأسماء، أرقام الهويات الشخصية، والعناوين، وأرقام التواصل، وصور المستخدم... وغيرها) وبيانات الدفع: (مثل البيانات التي يتم جمعها لأغراض إجراء عمليات الدفع، وأرقام الحسابات البنكية والبطاقات الائتمانية)، والبيانات المُتبادلة خلال تواصلنا معك (مثل طلبات دعم العملاء والبلاغات المُستلمة منك).",
            "- ملفات تعريف الارتباط (Cookies)، قد يقوم الموقع بتخزين ما يسمى بملفات تعريف الارتباط (Cookies) على الحاسوب الخاص بك عندما تقوم بزيارة الموقع. إن ملفات تعريف الارتباط (Cookies) هي جزء من البيانات التي تحدد هوية المستخدم بشكل فريد، كما يمكن استخدامها لتطوير معرفتك بالموقع ولفهم قاعدة مستخدم هذا الموقع على نحو أفضل.",
            "- إن معظم المتصفحات تقبل ملفات تعريف الارتباط تلقائياً، في الوقت ذاته يمكنك إعادة إعداد المتصفح الخاص بك لرفض كافة ملفات تعريف الارتباط أو لتلقي التنبيه عندما يتم إرسال ملفات تعريف الارتباط. كما يرجى ملاحظة: (أن كل أو بعض الخدمات التي يتم عرضها على هذه الموقع قد لا تعمل بشكل صحيح إذا قمت برفض ملفات تعريف الارتباط)، حيث إننا قد نقوم بربط المعلومات التي نقوم بتخزينها في ملفات تعريف الارتباط بأي معلومات تعريف شخصية تقدمها على موقعنا.",
            "- بمجرد زيارتك لموقعنا، يقوم الخادم الخاص بالموقع وبشكل تلقائي، بتسجيل عنوان بروتوكول شبكة الإنترنت IP الخاص بك (عنوان بروتوكول شبكة الإنترنت الخاص بك هو الرقم الخاص بجهاز الحاسوب الذي تستخدمه)، والذي يسمح للأجهزة الأخرى الموصولة بشبكة الإنترنت بتحديد وجهة البيانات الصادرة عنها، وجمع بعض المعلومات مثل نوع المتصفح ومحرك البحث، وتاريخ ووقت الزيارة وعنوان URL الخاص بأي موقع إلكتروني تتم منه إحالتك إلى الموقع، باستثناء التعرف على هويتك الشخصية.",
            "- قد يُطلب من المستخدم في بعض الأحيان الموافقة على تحديد الموقع الجغرافي له، للاستفادة من بعض الخدمات المقدمة في الموقع.",
          ],
        },
        {
          title: "كيف نجمع بياناتك الشخصية",
          paragraph: [
            "قد يتم جمع البيانات الشخصية بشكل مباشر من قبلكم وبطرق مختلفة، وذلك عند الاستخدامات الآتية:",
            "- المنتجات والخدمات - عند الدخول، أو إدخال بياناتكم، أو استخدام موقعنا، أو توقيع عقد لتصبح عميلاً لدينا وتستخدم أحد منتجاتنا أو خدماتنا.",
            "- التفاعلات المباشرة أو غير المباشرة، وذلك عند إكمال نموذج وإرساله إلينا عبر موقعنا، أو تطبيقات الهاتف المحمول، أو البريد الإلكتروني، أو البريد أو عبر مكالمة هاتفية إلى مركز الاتصال الخاص بنا. يتضمن ذلك أي بيانات شخصية تقدمها لنا عند إجراء استفسار أو المشاركة في عرض ترويجي أو بمجرد أي وسيلة تواصل معنا.",
            "- تفاعلات وسائل التواصل الاجتماعي، أي منشورات وتفاعلات تجريها معنا مباشرة على قنواتنا في وسائل التواصل الاجتماعي.",
            "- استبيانات التصفح - بيانات حول كيفية استخدامك لموقع الويب الخاص بنا، بما في ذلك تاريخ ووقت زيارتك ونوع متصفح الإنترنت الذي تستخدمه وكيف تمت إحالتك إلى موقع الويب الخاص بنا.",
            "- استطلاعات الويب، تمكّننا استطلاعات الويب الخاصة بنا عبر الإنترنت من جمع بيانات محددة مثل ملاحظاتك على شكل ومظهر موقعنا وتطبيقات الأجهزة المحمولة. بالإضافة إلى ذلك، قد نطلب أيضًا معلومات حول العديد من عناصر خدمة العملاء لدينا. علماً بأن ملاحظاتك حيوية ونقدرها وتمكننا من تحسين جودة خدمة العملاء التي نقدمها. بالإضافة إلى توفير أسمك والتفاصيل الأخرى يعد أمرًا اختياريا.",
          ],
        },
        {
          title: "الروابط بالمواقع الأخرى والأطراف الثالثة على شبكة الإنترنت",
          paragraph:
            "ينطبق هذا الإشعار على موقعنا وجميع الخدمات والبرامج التي يقدمها الموقع وإذا ما انتقلت إلى موقع آخر أو منصة أخرى من خلال موقعنا، يتعين عليك قراءة الإشعارات وسياسات الخصوصية الخاصة بذلك الموقع؛ لتحديد ممارسات أمن المعلومات وسياسة الخصوصية المعتمدة هناك.",
        },
        {
          title: "الغرض من جمع البيانات الشخصية",
          paragraph: [
            "يتم جمع واستخدام البيانات الشخصية لعدة أسباب، على سبيل المثال لا الحصر:",
            "- جمع البيانات الشخصية ومعالجتها لتلبية المتطلبات القضائية، والتنظيمية والتشغيلية والتطويرية.",
            "- استيفاء البيانات المطلوبة لتقديم الخدمات والتعاملات الإلكترونية.",
            "- فهم احتياجات الزوار لتحسين المنتجات والخدمات.",
            "- التمكين من تطوير وتشغيل المنتجات والخدمات وتحسينها أو تسويقها لعملاء الشركة.",
            "- السماح للمستخدمين بالوصول إلى الخدمات وإجراء المعاملات.",
            "- تحليل البيانات وإصدار التقارير التي تخدم متطلبات العمل اللازمة. على سبيل المثال التقارير الإحصائية.",
            "- أرشفة هذه المعلومات والبيانات و/أو استخدامها لإمكانية التواصل المستقبلي معكم.",
            "- حل ومعالجة الاستفسارات أو الشكاوى التي قد تكون لديك وتحسين تجربة المستخدم عبر جميع قنوات تواصل الشركة.",
          ],
        },
        {
          title: "حقوقك فيما يتعلق بمعالجة وحماية بياناتك الشخصية",
          paragraph: [
            {
              label: "- الحق في العلم: ",
              description: "يحق لصاحب البيانات الإحاطة علماً بالغرض من جمع ومعالجة بياناته الشخصية والأساس النظامي المعتبر لهذا الغرض، ومحتوى البيانات المطلوب معالجتها، وطريقة جمعها، ووسيلة حفظها، ولمن سيتم الإفصاح عنها (حيث يعتبر إشعار الخصوصية أحد الوسائل الممكنة التي من خلالها يمكن لصاحب البيانات ممارسة هذا الحق)."
            },
            {
              label: "- الحق في وصولك إلى بياناتك الشخصية: ",
              description: "يحق لصاحب البيانات طلب نسخة من بياناته الشخصية وذلك عن طريق القنوات المتاحة للوصول الى البيانات أو عبر البريد الالكتروني الموضح في بيانات التواصل أدناه."
            },
            {
              label: "- الحق في تصحيح بياناتك الشخصية: ",
              description: "يحق لصاحب البيانات طلب تصحيح بياناته الشخصية التي يرى أنها غير دقيقة أو غير صحيحة أو غير مكتملة وذلك عن طريق القنوات المعتمدة في الشركة، أو عبر البريد الالكتروني الموضح في بيانات التواصل أدناه."
            },
            {
              label: "- الحق في الإتلاف: ",
              description: "يحق لصاحب البيانات طلب إتلاف بياناته الشخصية التي انتهى الغرض من جمعها ومعالجتها في ظروف معينة بما لا يتعارض مع الأسس النظامية والمصالح المشروعة ومع انتهاء المدة النظامية والقانونية للاحتفاظ بها."
            },
            {
              label: "- الحق في الاعتراض: ",
              description: "يحق لصاحب البيانات الاعتراض على (جمع أو معالجة أو تخزين أو مشاركة أو افصاح) عن بياناته الشخصية بدون موافقته الصريحة أو الضمنية أو إذا كانت المعالجة لأغراض التسويق المباشر."
            },
            {
              label: "- الحق في تقييد المعالجة: ",
              description: "يحق لصاحب البيانات طلب تقييد المعالجة لبياناته الشخصية على غرض أو أكثر."
            },
            {
              label: "- الحق في نقل البيانات الشخصية: ",
              description: "يحق لصاحب البيانات طلب نقل بياناته الشخصية (المعالجة) والتي تم جمعها عن طريقه مباشرةً وبموافقته الصريحة لأي جهة حسب السياسات المتبعة في الشركة."
            },
            "<br />",
            "ولمزيد من التفاصيل عن معالجة بياناتك الشخصية وكيفية ممارسة حقوقك، يمكنك الاطلاع على نظام حماية البيانات الشخصية الصادر بالمرسوم الملكي رقم: (م/19) وتاريخ: 09/02/1443هـ.، ولوائحه التنفيذية."
          ],
        },
        {
          title: "مشاركة بياناتك الشخصية",
          paragraph: [
            "قد نقوم بتبادل ومعالجة ومشاركة أي معلومات وبيانات تقدمونها لنا بمعرفتكم وموافقتكم التامة و/أو نقوم بجمعها عنكم مع شركائنا والموردين من طرف ثالث، والجهات المختصة بإجراء التحليلات ومقدمي خدمات محرك البحث والمعلنين، ويتم مشاركة البيانات بطرق محددة ولأغراض مشروعة مبنية على أساس نظامي أو احتياج عملي مبرر يهدف إلى تحقيق مصلحة عامة دون إلحاق أي ضرر بالمصالح الوطنية، أو أنشطة الجهات، أو خصوصية الأفراد، أو سلامة البيئة – ويستثنى من ذلك البيانات والجهات المستثناة بأوامر سامية.",
            "عند مشاركة بياناتك الشخصية، يتم الحرص على مشاركتها من خلال بيئة آمنة وموثوقة؛ وفقاً للأنظمة واللوائح والسياسات ذات العلاقة، قد نتخذ خطوات إضافية لضمان حماية بياناتك من خلال توقيع اتفاقية لمشاركة البيانات بين الطرفين وفقاً لشروط وأحكام محددة متوافقة مع مبادئ مشاركة البيانات.",
          ]
        },
      ],
    },
  ],
};
