import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as beforeLoadActions from "../../actions/beforeLoadActions";
import { requestToken } from "../../actions/authActions";
import * as profileActions from "../../actions/establishmentProfileActions";
import queryString from "query-string";
import Lottie from "react-lottie";
import * as animationLoader from "../../../public/animation/loading.json";

export class RedirectPage extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    const param = queryString.parse(this.props.location.search);
    let userInfo;
    requestToken(param.code, param.state).then((token) => {
      if (token) {
        if (localStorage.getItem("selected")) {
          this.props.saveSelectedEstablishment(
            JSON.parse(localStorage.getItem("selected"))
          );
        }
        this.props.actions.getUserProfile().then((res) => (userInfo = res));
      }
    });
    console.log("after requestToken, the user info is ", userInfo);
    this.redirect(userInfo);
  }

  redirect(userInfo) {
    const establishmentProfile = JSON.parse(localStorage.getItem("selected"));

    if (establishmentProfile) {
      const isProfileFirstLogin =
        establishmentProfile.id && !establishmentProfile.is_confirmed;
      if (
        isProfileFirstLogin ||
        (userInfo && userInfo.is_email_editable && userInfo.email === "") ||
        (userInfo && userInfo.is_email_editable && !userInfo.email)
      ) {
        this.props.history.push("/establishmentProfile");
      } else {
        this.props.history.push("/establishmentBasicDurationsPage");
      }
    } else {
      this.props.history.push("/establishmentProfile");
    }
  }

  render() {
    return (
      <div>
        <div className="loading-screen">
          <div className="loader-div">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationLoader,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={160}
              width={160}
            />
            <p className="loader-text">يرجى الانتظار..</p>
          </div>
        </div>
      </div>
    );
  }
}

RedirectPage.propTypes = {
  state: PropTypes.object,
  history: PropTypes.object,
};

RedirectPage.contextTypes = {
  actions: PropTypes.object,
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    state: state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(beforeLoadActions, dispatch),
    saveSelectedEstablishment: (establishment) =>
      dispatch(profileActions.loadEstablishmentProfile(establishment)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage);
