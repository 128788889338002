import request from "../../common/request";
import Cookies from "js-cookie";
import { refreshToken } from "../../common/request";

export const setup = () => {
  const url = new URL(window.location.href); //(location.href);
  const env_url = `${url.origin}${url.pathname}env.js`;

  let file_content = "";

  let rawFile = new XMLHttpRequest();
  rawFile.open("GET", env_url, false);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status == 0) {
        file_content = rawFile.responseText;
      }
    }
  };

  rawFile.send(null);

  let script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = file_content;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function keepTokenAlive() {
  while (true) {
    if (process.env.REACT_APP_IS_QIWA_SSO_ENABLED == "false") {
      if (window.kc) {
        refreshToken();
      }
      await sleep(60000);
    } else {
      let accessToken = Cookies.get("token");
      console.log(
        " keepTokenAlive : access token from storage ",
        localStorage.getItem("token")
      );
      console.log(" keepTokenAlive : token from cookie ", Cookies.get("token"));
      if (accessToken) {
        try {
          request(
            `/qiwa/refresh_token`,
            "POST",
            {
              access_token: accessToken,
            },
            {},
            false
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(" from refresh token endpoint ", data);
              localStorage.setItem("token", data.Access_token);
            })
            .catch((error) => {
              throw error;
            });
        } catch (error) {
          throw error;
        }
      } else {
        console.log("can't find the token in the cookie or storage");
      }
    }
    await sleep(180000);
  }
}
