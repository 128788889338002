import React from "react";
import Content from "../../containers/Content/Content";
import PropTypes from "prop-types";

import { Card, CardBody, CardGroup, Col, Row } from "reactstrap";
import { connect } from "react-redux";

class FailedPaymentPage extends React.Component {
  componentDidMount() {
    const { isLogin } = this.props;
    /*if (!isLogin) {
      window.location = process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }
    */
  }

  render() {
    return (
      <div>
        <div className="container-fluid-size">
          <Row className="justify-content-center">
            <Col>
              <CardGroup className="mb-0">
                <Card style={{ height: "200px" }}>
                  <CardBody className="card-body">
                    <div className="success-page">
                      <h2 style={{ color: "red" }}>تعذر الدفع !</h2>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

FailedPaymentPage.propTypes = {
  isLogin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLogin: state.auth.token !== null,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FailedPaymentPage);
