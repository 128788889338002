import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchEstablishmentFastTrackResult } from "../../actions/establishmentSelfAssessmentResultActions";
import isEqual from "lodash/isEqual";
import { Col, Row, Button } from "reactstrap";
import QRCode from "qrcode.react";
import moment from "moment";
import ComplianceCertPDF from "./ComplianceCertPdf";

import "./styles.css";

let emptyEstablishmentProfile = {
  establishment_id: "",
  establishment_name: "",
  english_name: "",
  labor_office_id: "",
  sequence_id: "",
  establishment_status_id: "",
  establishment_status: "",
  main_economic_activity_id: "",
  sub_economic_activity_id: "",
  economic_activity_id: "",
  economic_activity_name: "",
  color_id: "",
  nitaqat_color: "",
  size_id: "",
  size_name: "",
  color_code: "",
  saudis: "",
  foreigners: "",
  total_laborer_count: "",
  assessment_type: "",
};

const emptyEstablishmentSelfAssessmentResult = {
  standard_categories: [],
  assessment: {
    standard_categories: [],
  },
  establishment: {
    establishment_name: "",
    labor_office_id: "",
    sequence_id: "",
  },
  standard_categories_scores: [],
  final_result: 0,
  max_penalty: 0,
  min_penalty: 0,
  period_details: {
    name: "",
    start_date: null,
    end_date: null,
  },
  user: {
    id: "",
    name_ar: { String: "" },
    name_en: { String: "" },
    phone_number: { String: "" },
    email: { String: "" },
    id_number: "",
  },
  questions: [],
  violations_count: 0,
  timestamps: {
    assigned_at: {
      String: "",
      Valid: false,
    },
    first_opened_at: {
      String: "",
      Valid: false,
    },
    submitted_at: {
      String: "",
      Valid: false,
    },
  },
};

const emptyAuditReport = {
  color: "black",
  certificate_name: "",
  certificate_number: "",
  auditing_score: "",
  certificate_end_date: { String: "", Valid: true },
  certificate_status: "",
};

class EstablishmentFastTrackResultPage extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      establishmentProfile: { ...this.props.establishmentProfile },
      establishmentSelfAssessmentResult: {
        reports: { ...emptyEstablishmentSelfAssessmentResult },
        audit_report: { ...emptyAuditReport },
      },
      period_id: "",
      assessment_id: "",
      collapsed: true,
    };
    this.getCertificateImage = this.getCertificateImage.bind(this);
  }

  componentDidMount() {
   /* const { isLogin } = this.props;
    if (!isLogin) {
      window.location =
        process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }
*/
    if (
      this.props.match.params.periodId &&
      this.props.match.params.assessmentId &&
      this.props.match.params.establishmentId &&
      this.props.match.params.assessment_version
    ) {
      this.props.fetchEstablishmentFastTrackResult(
        this.props.match.params.periodId,
        this.props.match.params.assessmentId,
        this.props.match.params.establishmentId,
        this.props.match.params.assessment_version
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.establishmentProfile, this.props.establishmentProfile)
    ) {
      this.setState({
        establishmentProfile:
          this.props.establishmentProfile || emptyEstablishmentProfile,
      });
    }
    if (
      !isEqual(
        prevProps.establishmentSelfAssessmentResult,
        this.props.establishmentSelfAssessmentResult
      )
    ) {
      this.setState({
        establishmentSelfAssessmentResult: this.props
          .establishmentSelfAssessmentResult || {
          reports: { ...emptyEstablishmentSelfAssessmentResult },
          audit_report: { ...emptyAuditReport },
        },
      });
    }
  }

  getCertificateImage(color) {
    switch (color) {
      case "#c99c50": {
        return (
          <img
            src={"img/gold-certificate-logo.svg"}
            style={{ width: 150, float: "left" }}
            alt="MOL logo"
          />
        );
      }
      case "#357e4a": {
        return (
          <img
            src={"img/green-certificate-logo.svg"}
            style={{ width: 150, float: "left" }}
            alt="MOL logo"
          />
        );
      }
      case "#a8abb3": {
        return (
          <img
            src={"img/gray-certificate-logo.svg"}
            style={{ width: 150, float: "left" }}
            alt="MOL logo"
          />
        );
      }

      case "#000000": {
        return (
          <img
            src={"img/Stamp.png"}
            style={{ width: 150, float: "left" }}
            alt="MOL logo"
          />
        );
      }
      default: {
        return <span />;
      }
    }
  }
  render() {
    const {
      establishmentSelfAssessmentResult,
      establishmentProfile,
      collapsed,
    } = this.state;

    const MOL_logo = (
      <img src={"img/MHRSD-Logo.svg"} style={{ width: 150 }} alt="MOL logo" />
    );
    const IMS = (
      <img
        src={"img/IMS-Logo.svg"}
        style={{ width: 150, float: "left" }}
        alt="MOL logo"
      />
    );

    const Certificate_Logo = this.getCertificateImage(
      establishmentSelfAssessmentResult.audit_report.color
    );

    return (
      <span>
        <div className="container-fluid-size result-report-block website-container-margins doNotPrint">
          {establishmentSelfAssessmentResult.audit_report.certificate_status ==
            "Certified" && (
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Button
                      className="span-float"
                      color="link"
                      size="sm"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <i className="fa fa-file-pdf-o" /> تحميل / طباعة
                    </Button>
                  </Col>
                </Row>

                <Row
                  className="establishment-assessment-card bgImgCenter"
                  style={{
                    border: `6px solid ${establishmentSelfAssessmentResult.audit_report.color}`,
                    borderRadius: "20px",
                  }}
                >
                  <Col>
                    <Row>
                      <Col>{MOL_logo}</Col>
                      <Col>{IMS}</Col>
                    </Row>
                    <Row>
                      <Col className="card-header-style">
                        <strong
                          style={{ fontSize: "56px" }}
                          className="certificate-main-title"
                        >
                          شهادة الامتثال
                        </strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "200px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "36px" }}>
                          تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذه
                          الشهادة لشركة
                        </strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="card-header-style">
                        <strong
                          style={{ fontSize: "40px" }}
                        >{`${establishmentSelfAssessmentResult.reports.establishment.establishment_name} ( ${establishmentSelfAssessmentResult.reports.establishment.labor_office_id} / ${establishmentSelfAssessmentResult.reports.establishment.sequence_id} ) `}</strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "36px" }}>
                          وذلك لامتثالها لقوانيين وأنظمة الوزارة بنسبة{" "}
                          {
                            establishmentSelfAssessmentResult.audit_report
                              .auditing_score
                          }
                          %
                        </strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "36px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <div className="circlee">{Certificate_Logo}</div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="bottom-content">
                        <Row style={{ color: "#000000", fontSize: "18px" }}>
                          رقم الشهادة{" "}
                          {
                            establishmentSelfAssessmentResult.audit_report
                              .certificate_number
                          }
                        </Row>
                        <Row style={{ color: "#000000", fontSize: "18px" }}>
                          تاريخ انتهاء الشهادة{" "}
                          {moment(
                            establishmentSelfAssessmentResult.audit_report
                              .certificate_end_date.String
                          ).format("YYYY-MM-DD")}
                        </Row>
                      </Col>
                      <Col>
                        <Row style={{ color: "#000000" }}>
                          <Col>
                            {establishmentSelfAssessmentResult.audit_report
                              .certificate_number !== null && (
                              <QRCode
                                style={{ float: "left" }}
                                value={
                                  establishmentSelfAssessmentResult.audit_report
                                    .certificate_number
                                }
                                size={128}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}
                                renderAs={"svg"}
                                imageSettings={{
                                  src: "img/IMS-Logo.png",
                                  x: null,
                                  y: null,
                                  height: 30,
                                  width: 30,
                                  excavate: false,
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000000",
                            float: "left",
                            fontSize: "18px",
                          }}
                        >
                          التحقق من صحة الشهادة
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {establishmentSelfAssessmentResult.audit_report.certificate_status !==
            "Certified" && (
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Button
                      className="span-float"
                      color="link"
                      size="sm"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <i className="fa fa-file-pdf-o" /> تحميل / طباعة
                    </Button>
                  </Col>
                </Row>

                <Row
                  className="establishment-assessment-card bgImgCenter"
                  style={{
                    border: `6px solid ${establishmentSelfAssessmentResult.audit_report.color}`,
                    borderRadius: "20px",
                  }}
                >
                  <Col>
                    <Row>
                      <Col>{MOL_logo}</Col>
                      <Col>{IMS}</Col>
                    </Row>
                    <Row>
                      <Col className="card-header-style">
                        <strong
                          style={{ fontSize: "56px" }}
                          className="certificate-main-title"
                        >
                          إشعار نتيجة التدقيق
                        </strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="card-header-style">
                        <Row
                          style={{
                            border: `2px solid darkgreen`,
                            borderRadius: "20px",
                            marginLeft: "200px",
                            marginRight: "200px",
                            marginTop: "100px",
                            marginBottom: "100px",
                            padding: "40px",
                          }}
                        >
                          <Col>
                            <Row style={{ color: "#000000", fontSize: "20px" }}>
                              <Col>نسبة الامتثال</Col>
                            </Row>
                            <Row
                              className="certificate-main-title"
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                              }}
                            >
                              <Col>
                                {
                                  establishmentSelfAssessmentResult.audit_report
                                    .auditing_score
                                }
                                %
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row style={{ color: "#000000", fontSize: "20px" }}>
                              <Col>حالة المنشأة</Col>
                            </Row>
                            <Row
                              className="certificate-main-title"
                              style={{ color: "#000000", fontSize: "20px" }}
                            >
                              <Col>لم تجتاز النسبة المطلوبة</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "36px" }}>
                          تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذا
                          الاشعار لشركة
                        </strong>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="card-header-style certificate-est-name">
                        <strong
                          style={{ fontSize: "40px" }}
                          className="certificate-main-title"
                        >{`${establishmentSelfAssessmentResult.reports.establishment.establishment_name} ( ${establishmentSelfAssessmentResult.reports.establishment.labor_office_id} / ${establishmentSelfAssessmentResult.reports.establishment.sequence_id} ) `}</strong>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "36px" }}>
                          وذلك لعدم اجتيازها النسبة المطلوبة للحصول على شهادة
                          الامتثال،
                        </strong>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <strong style={{ fontSize: "36px" }}>
                          علماً بأن الحد الأدنى للحصول على الشهادة هو 80%
                        </strong>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "36px", marginBottom: "50px" }}>
                      <Col className="card-header-style">
                        <div className="circlee">{Certificate_Logo}</div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="bottom-content">
                        <Row style={{ color: "#000000", fontSize: "18px" }}>
                          رقم الشهادة{" "}
                          {
                            establishmentSelfAssessmentResult.audit_report
                              .certificate_number
                          }
                        </Row>
                        <Row style={{ color: "#000000", fontSize: "18px" }}>
                          تاريخ انتهاء الشهادة{" "}
                          {moment(
                            establishmentSelfAssessmentResult.audit_report
                              .certificate_end_date.String
                          ).format("YYYY-MM-DD")}
                        </Row>
                      </Col>
                      <Col>
                        <Row style={{ color: "#000000" }}>
                          <Col>
                            {establishmentSelfAssessmentResult.audit_report
                              .certificate_number && (
                              <QRCode
                                style={{ float: "left" }}
                                value={
                                  establishmentSelfAssessmentResult.audit_report
                                    .certificate_number
                                }
                                size={128}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}
                                renderAs={"svg"}
                                imageSettings={{
                                  src: "img/IMS-Logo.png",
                                  x: null,
                                  y: null,
                                  height: 30,
                                  width: 30,
                                  excavate: false,
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row
                          style={{
                            color: "#000000",
                            float: "left",
                            fontSize: "18px",
                          }}
                        >
                          التحقق من صحة الشهادة
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
        <span className="toPrint">
          {establishmentSelfAssessmentResult ? (
            <ComplianceCertPDF
              establishmentSelfAssessmentResult={
                establishmentSelfAssessmentResult
              }
              certificateLogo={Certificate_Logo}
              MOL_logo={MOL_logo}
              IMS={IMS}
            />
          ) : null}
        </span>
      </span>
    );
  }
}

EstablishmentFastTrackResultPage.propTypes = {
  establishmentProfile: PropTypes.object,
  establishmentSelfAssessmentResult: PropTypes.object,
  establishmentSelfAssessmentResultFetchingError: PropTypes.object,
  ajaxStatus: PropTypes.object,
  history: PropTypes.object.isRequired,
};

EstablishmentFastTrackResultPage.defaultProps = {
  establishmentProfile: null,
  establishmentSelfAssessmentResult: null,
  establishmentSelfAssessmentResultFetchingError: null,
};

const mapStateToProps = (state) => ({
  establishmentProfile: state.establishmentSelfAssessmentResult
    .establishmentSelfAssessmentResult
    ? state.establishmentSelfAssessmentResult.establishmentSelfAssessmentResult
        .establishment
    : emptyEstablishmentProfile,
  establishmentSelfAssessmentResult:
    state.establishmentSelfAssessmentResult.establishmentSelfAssessmentResult,
  establishmentSelfAssessmentResultFetchingError:
    state.establishmentSelfAssessmentResult
      .establishmentSelfAssessmentResultFetchingError,
  ajaxStatus: state.ajaxStatus,
  isLogin: state.auth.token !== null,
});

const mapDispatchToProps = {
  // fetchEstablishmentProfile: fetchEstablishmentProfile,
  fetchEstablishmentFastTrackResult: fetchEstablishmentFastTrackResult,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentFastTrackResultPage);
