import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import {
  createTraining,
  fetchTraining,
  updateTraining,
} from "../../actions/trainingActions";
import * as TrainingSubjectActions from "../../actions/trainingSubjectActions";

import {
  listFormattedForDropdown,
  listFormattedForSearchDropdown,
} from "../../selectors/selectors";

// import EstablishmentTrainingForm from "./EstablishmentTrainingForm";
import EstablishmentTrainingDetails from "./EstablishmentTrainingDetails";

import toastr from "toastr";
import EstablishmentTrainingCourseDetails from "./EstablishmentTrainingCourseDetails";

export const emptyTraining = {
  id: "",
  email: "",
  address: "",
  subject_id: "",
  mobile: "",
  status: "",
};
export const emptyTrainingSubject = {
  id: "",
  name: "",
  descripion: "",
  details: "",
};

export class ManageEstablishmentTrainingPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      training: { ...emptyTraining },
      trainingSubject: { ...emptyTrainingSubject },
      errors: {},
    };

    this.searchTrainingCategories = this.searchTrainingCategories.bind(this);
    this.reset = this.reset.bind(this);
    this.redirectToEditTraining = this.redirectToEditTraining.bind(this);
  }

  componentDidMount() {
    const { isLogin } = this.props;
    /*if (!isLogin) {
      window.location = process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }
    */
    if (this.props.match.params.id) {
      this.props.onFetchTraining(
        this.props.match.params.establishment_id,
        this.props.match.params.id
      );

    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.training, this.props.training)) {
      this.setState({ training: this.props.training || emptyTraining });
    }
    if (!isEqual(prevProps.trainingSubject, this.props.trainingSubject)) {
      this.setState({ trainingSubject: this.props.trainingSubject || emptyTrainingSubject });
    }

    if (prevProps.trainingCreating && !this.props.trainingCreating) {
      if (!this.props.trainingCreatingError) {
        toastr.success("تم إضافة طلب التدريب بنجاح.");
        this.redirect();
      } else {
        toastr.error(this.props.trainingCreatingError.reason);
      }
    }

    if (prevProps.trainingUpdating && !this.props.trainingUpdating) {
      if (!this.props.trainingUpdatingError) {
        toastr.success("تم إضافة طلب التدريب بنجاح.");
        this.redirect();
      } else {
        toastr.error(this.props.trainingUpdatingError.reason);
      }
    }
  }

  reset() {
    if (this.props.match.params.id) {
      this.setState({ training: this.props.training });
    } else {
      this.setState({ training: { ...emptyTraining } });
    }
  }

  // for saerch loading
  searchTrainingCategories(input) {
    // should used action for search
    this.props.onGetTrainingCategories();
  }

  redirect() {
    this.props.history.push("/establishmentTrainings/trainings");
  }
  redirectToEditTraining() {
    const { training, trainingSubject } = this.state;
    this.props.history.push(
      `/establishmentTrainingRegister/${this.props.match.params.establishment_id}/${this.props.match.params.id}`
    );
  }
  render() {

    const { trainingSubject, training } = this.state;
    return (
      <div className="website-container-margins">
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
           <EstablishmentTrainingDetails training={training} />
        </div>
      </div>
    );
  }
}

ManageEstablishmentTrainingPage.propTypes = {
  training: PropTypes.instanceOf(Object),
  trainingSubject: PropTypes.instanceOf(Object),
  trainingCreating: PropTypes.bool.isRequired,
  trainingCreatingError: PropTypes.instanceOf(Object),
  trainingFetching: PropTypes.bool.isRequired,
  trainingFetchingError: PropTypes.instanceOf(Object),
  trainingUpdating: PropTypes.bool.isRequired,
  trainingUpdatingError: PropTypes.instanceOf(Object),
  onCreateTraining: PropTypes.func.isRequired,
  onFetchTraining: PropTypes.func.isRequired,
  onUpdateTraining: PropTypes.func.isRequired,
  onFetchTrainingSubject: PropTypes.func.isRequired,
  trainingSubjects: PropTypes.array.isRequired,
  isLogin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

ManageEstablishmentTrainingPage.defaultProps = {
  training: null,
  trainingSubject: null,
  trainingCreatingError: null,
  trainingFetchingError: null,
  trainingUpdatingError: null,
};

const mapStateToProps = (state) => ({
  training: state.trainings.training,
  trainingCreating: state.trainings.trainingCreating,
  trainingCreatingError: state.trainings.trainingCreatingError,
  trainingFetching: state.trainings.trainingFetching,
  trainingFetchingError: state.trainings.trainingFetchingError,
  trainingUpdating: state.trainings.trainingUpdating,
  trainingUpdatingError: state.trainings.trainingUpdatingError,
  isLogin: state.auth.token !== null,
  trainingSubject: state.trainingSubjects.trainingSubject,
  trainingSubjects: listFormattedForDropdown(
    state.trainingSubjects.trainingSubjectsList
  ),
});

const mapDispatchToProps = {
  onCreateTraining: createTraining,
  onFetchTraining: fetchTraining,
  onUpdateTraining: updateTraining,
  onFetchTrainingSubjects: TrainingSubjectActions.fetchTrainingSubjects,
  onFetchTrainingSubject: TrainingSubjectActions.fetchTrainingSubject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageEstablishmentTrainingPage);
